const initialState = {
    woSelectedImages: [],
    lineItems:[],
    serviceLineItems:[],
    proposalCopied:{}
  };
  
  const proposalReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_WO_SELECTED_IMAGES':
        return { ...state, woSelectedImages: action.payload };
        case 'ADD_REMOVE_LINE_ITEMS':
          return { ...state, lineItems: action.payload };
        case 'ADD_REMOVE_SERVICE_LINES':
          return { ...state, serviceLineItems: action.payload };
          case 'COPY_ADD_INFO':
            return { ...state, proposalCopied: action.payload };
          default:
        return state;
    }
  };
  
  export default proposalReducer;
