import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Customizer from '../../redux/reducers/customizer';
import authReducer from '../../redux/reducers/auth/authReducer';
import commonReducer from '../../redux/reducers/common';
import contactReducer from '../../redux/reducers/contacts';
import siteReducer from '../../redux/reducers/sites';
import campaignReducer from '../../redux/reducers/campaigns';
import proposalReducer from '@src/redux/reducers/proposal';
import zoneReducer from '@src/redux/reducers/zones'
const authPersistConfig = {
    key: 'root',
    storage: storage,
    debug: false,
}

const reducerAuth = persistReducer(authPersistConfig, authReducer)

const rootReducer = combineReducers({
    auth: reducerAuth,
    Customizer: Customizer,
    Common: commonReducer,
    Contact: contactReducer,
    Site: siteReducer,
    Campaign: campaignReducer,
    Proposal:proposalReducer,
    Zone:zoneReducer
})
export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)