const initialState = {
    siteList: [],
    isWorkorderLoaded: false,
    isProposalLoaded: false,
}

const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_LIST':
            return {...state, siteList: action.payload}
        case 'RELOAD_WORKORDER':
            return {...state, isWorkorderLoaded: action.payload}
        case 'RELOAD_PROPOSAL':
            return {...state, isProposalLoaded: action.payload}
            case 'CLEAR_SITE_LIST':
            return {...state, 
                siteList: []}
        default:
            return state
    }
}

export default siteReducer
